/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.component {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #f0f2f5;
  background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
}
.card {
  width: 450px;
}
.logo {
  width: 200px;
  height: 80px;
  margin: 0 auto;
}
.desc {
  text-align: center;
  margin-top: 12px;
  margin-bottom: 40px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  display: flex;
  align-items: center;
}
.desc .logo {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.opeartions {
  display: flex;
  justify-content: space-between;
  margin: 0 -12px;
  margin-bottom: 12px;
}
.footer {
  position: fixed;
  bottom: 20px;
  text-align: center;
  margin-top: 24px;
  font-size: 14px;
}
.footer a {
  color: rgba(0, 0, 0, 0.85);
  text-decoration: none;
}
.footer a:hover {
  color: #1890ff;
}
