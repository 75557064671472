/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/**
 cover目录用于样式覆盖
*/
html,
body,
#root {
  height: 100%;
}
.textTip {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}
