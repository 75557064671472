/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.logo {
  width: 200px;
  height: 59px;
  transform: scale(0.6);
  transition: opacity 0.2s linear;
}
.logo_sm {
  width: 80px;
  height: 80px;
  transition: opacity 0.2s linear;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
}
