/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.head {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 16px;
  background-color: #ffffff;
}
.head_crumbs {
  font-size: 20px;
  font-weight: 600;
}
.head_line {
  width: 0;
  height: 18px;
  border-left: 3px solid #1890ff;
  margin-right: 5px;
}
.content {
  padding: 16px;
}
